import api from '@api'
import { isLoggedIn } from '@utils/auth'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformServiceCatalogForServer,
  transformServiceCatalog,
} from '@data/service-catalog'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
} from '@/src/data/qualification'
import { transformTicketForServer, transformTicketForList } from '@data/ticket'
import { transformControl } from '@data/form'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function createServiceCatalogApi(data) {
  return api
    .post('/service_catalog', transformServiceCatalogForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('service'),
      }),
    })
    .then(transformServiceCatalog)
}

export function updateServiceCatalogApi(data) {
  return api
    .patch(
      `/service_catalog/${data.id}`,
      transformServiceCatalogForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('service'),
        }),
      }
    )
    .then(transformServiceCatalog)
}

export function getServiceCatalogApi(
  id,
  additionalParams = {},
  isPublic = false
) {
  const prefix = !isLoggedIn() ? '/public' : isPublic ? '/cportal' : ''
  return api
    .get(`${prefix}/service_catalog/${id}`, {
      params: {
        ...additionalParams,
      },
    })
    .then(transformServiceCatalog)
}

const buildQuery = (categoryId, name, status, ids) => {
  return buildFlatQualificationStructure([
    ...(categoryId
      ? [
          buildRelationalQualificationStructure(
            'categoryId',
            'equal',
            categoryId,
            'long',
            'db'
          ),
        ]
      : []),
    ...(name ? [buildNameFilterQuery(name)] : []),
    ...(status
      ? [
          buildRelationalQualificationStructure(
            'serviceCatalogStatus',
            'equal',
            status,
            'enum',
            'db'
          ),
        ]
      : []),
    ...(ids
      ? [buildRelationalQualificationStructure('id', 'in', ids, 'long', 'db')]
      : []),
  ])
}

export function getSystemFieldsApi() {
  return api.get('/supporteddata/systemfield/service_catalog').then((data) => {
    return (data || []).map((control) => transformControl(control))
  })
}

export function getServiceCatalogsApi(
  { categoryId, name, status, isPublic, ids },
  limit,
  offset
) {
  const prefix = !isLoggedIn() ? '/public' : isPublic ? '/cportal' : ''
  return api
    .post(
      `${prefix}/service_catalog/search/byqual`,
      {
        qualDetails:
          categoryId || name || status || ids
            ? buildQuery(categoryId, name, status, ids)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformServiceCatalog),
        total: data.totalCount,
      }
    })
}

export function deleteServiceApi(id) {
  return api.delete(`service_catalog/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('service'),
    }),
  })
}

export function cloneServiceApi(id) {
  return api
    .post(
      `/service_catalog/${id}`,
      {},
      {
        message: __rootT('created_successfully', {
          resource: __rootTc('service'),
        }),
      }
    )
    .then(transformServiceCatalog)
}

export function createServiceRequestApi(serviceId, data, isPublic = false) {
  const prefix = !isLoggedIn() ? '/public' : isPublic ? '/cportal' : ''
  return api
    .post(
      `${prefix}/service_catalog/${serviceId}/request`,
      transformTicketForServer(data),
      {
        message: __rootT('created_successfully', {
          resource: `${__rootTc('service')} ${__rootTc('request')}`,
        }),
      }
    )
    .then(transformTicketForList)
}
